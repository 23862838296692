export enum SmartAddressBounds {
  COUNTRY = 'country',
  REGION = 'region',
  AREA = 'area',
  CITY = 'city',
  SETTLEMENT = 'settlement',
  STREET = 'street',
  HOUSE = 'house',
  FLAT = 'flat',
}

export const cityDefaultValue = {
  city: '',
  city_area: '',
  city_district: '',
  city_district_fias_id: '',
  city_district_kladr_id: '',
  city_district_type: '',
  city_district_type_full: '',
  city_district_with_type: '',
  city_fias_id: '',
  city_kladr_id: '',
  city_type: '',
  city_type_full: '',
  city_with_type: '',
};

export const settlementDefaultValue = {
  settlement: '',
  settlement_fias_id: '',
  settlement_kladr_id: '',
  settlement_type: '',
  settlement_type_full: '',
};

export const streetDefaultValue = {
  street: '',
  street_fias_id: '',
  street_kladr_id: '',
  street_type: '',
  street_type_full: '',
  street_with_type: '',
};

export const houseDefaultValue = {
  house: '',
  house_fias_id: '',
  house_kladr_id: '',
  house_type_full: '',
  house_type: '',
  block_type: '',
  block: '',
  block_type_full: '',
};

export const flatDefaultValue = {
  flat: '',
  flat_area: '',
  flat_type: '',
  flat_type_full: '',
  flat_fias_id: '',
};

export const formDefaultValue = { city: '', street: '', house: '', flat: '' };

export const DEFAULT_FIAS_ID = '00000000-0000-0000-0000-000000000000';
