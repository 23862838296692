import { QUERY_KEYS, Endpoints } from '@shared/constants';
import { useDebounce, useStores } from '@shared/hooks';
import {
  fetchData,
  getActiveCodeRisks,
  getInsuranceSum,
  getSubObjects,
} from '@shared/utils';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import type { GetPrice } from './types';
import { prepareLimits } from '@entities/adapters/insurance-period-and-limits-adapter';

const debounceInsuranceSumTime = 1000;
const debounceRisksTime = 500;

export const useGetPrice = () => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      initProductStore: { initState },
      productStore: {
        formState,
        price: { promoCode },
        setPrice,
      },
    },
  } = useStores();

  const risks = formState.InsuranceRisks?.risks || initState.risks;
  const limits =
    formState.InsurancePeriodAndLimits?.limits ||
    prepareLimits(initState.subobjects);
  const isHasLiability = Boolean(limits?.Liability_1);

  const insuranceSum = useMemo(
    () => getInsuranceSum(limits),
    [limits, getInsuranceSum]
  );

  const subObjects = useMemo(
    () => getSubObjects(limits),
    [limits, getSubObjects]
  );

  const filteredRisks = useMemo(
    () => getActiveCodeRisks(risks, isHasLiability),
    [risks, getActiveCodeRisks, isHasLiability]
  );

  const debounceInsuranceSum = useDebounce(
    insuranceSum,
    debounceInsuranceSumTime
  );
  const debounceRisks = useDebounce(filteredRisks, debounceRisksTime);

  const isEnabledFetchingGetPrice = Boolean(
    debounceRisks && debounceInsuranceSum
  );

  const query = useQuery(
    [QUERY_KEYS.getPrices, debounceRisks, debounceInsuranceSum, promoCode],
    fetchData<GetPrice>({
      url: Endpoints.GET_PRICES,
      method: 'post',
      data: {
        productCode: initState.code,
        insuranceSum: debounceInsuranceSum,
        risks: debounceRisks,
        personProperties: {
          subObjects,
          //TODO: Изменить после добавления смарт компонента
          address: {
            regionCode: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
            address: 'г Москва, ул Цандера, д 10, кв 1',
            addressCode: 'cfa23745-efe0-4083-b6ed-bafc0848405a',
          },
        },
        promoCode,
      },
    }),
    {
      enabled: isEnabledFetchingGetPrice,
      onSuccess(data) {
        setPrice(data);
        setErrorRetry(false);
      },
    }
  );

  return query;
};
