/* eslint-disable indent */
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export type SiteAgreementsProps = {
  order?: string;
  marginBottom?: number;
};

export const SubmitWrapper = styled.div<{ isShowOtp: boolean }>`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 24px 16px 20px;
  border-radius: 0 0 24px 24px;
  gap: 24px;
  margin-top: -36px;

  ${media.desktop} {
    padding: ${({ isShowOtp }) => (isShowOtp ? '24px' : '32px 24px 24px')};
    gap: 32px;
    margin-top: -40px;
  }
`;

export const SiteAgreements = styled.div<SiteAgreementsProps>`
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};

  ${media.desktop} {
    order: ${({ order }) => (order ? order : '-1')};
  }

  a {
    color: ${({ theme }) =>
      theme.common?.a?.color
        ? theme.common.a.color
        : theme.colors.text.interactive};
    text-decoration: ${({ theme }) =>
      theme.common?.a?.decoration ? theme.common.a.decoration : 'none'};
  }
`;

export const AdaptiveContainer = styled.div`
  ${mixins.flex({
    horizontal: false,
    wrap: false,
    gap: 16,
    alignItems: 'center',
  })};
  ${media.desktop} {
    ${mixins.flex({
      horizontal: true,
      wrap: false,
      gap: 24,
    })};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 16px;
  ${media.desktop} {
    flex-direction: row;
  }
  flex-direction: column;
`;

export const StyledPolicyLink = styled.a`
  display: inline-block;

  ${media.desktop} {
    display: block;
  }
`;
