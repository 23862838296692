import { useStores } from '@shared/hooks';
import { Endpoints } from '@shared/constants';
import { useQuery } from 'react-query';
import { fetchData } from '@shared/utils';
import type { InsuredAmountConfigurationOptions } from '@pulse-smart-components-kit/insured-amount-configuration';
import { MOCK_PERIOD_AND_LIMITS_RISKS } from '../mock'; // Если нужно, чтобы лимиты зависели от рисков, то риски берем из стора
import { useMemo } from 'react';

export const useHandleGetSubobjects = () => {
  const {
    MainStore: {
      initProductStore: { setInitState, initState },
    },
  } = useStores();

  const enabled = useMemo(() => {
    return (
      Boolean(MOCK_PERIOD_AND_LIMITS_RISKS?.length) && Boolean(initState.code)
    );
  }, [MOCK_PERIOD_AND_LIMITS_RISKS?.length, initState.code]);

  const { data, isLoading, refetch } = useQuery(
    ['formIFLGetSubobjects', MOCK_PERIOD_AND_LIMITS_RISKS],
    fetchData<InsuredAmountConfigurationOptions>({
      url: Endpoints.GET_SUBOBJECTS,
      method: 'post',
      data: {
        productCode: initState.code,
        region: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5', // TODO: получать из стора
        risks: MOCK_PERIOD_AND_LIMITS_RISKS,
      },
    }),
    {
      enabled,
      onSuccess(data) {
        setInitState({ ...initState, ...{ subobjects: data } });
      },
    }
  );

  return { isLoading, data, refetch };
};
