import commonDictionary from '../../ru/common.dic.json';
import insurancePeriodAndLimits from '../../ru/insurance-period-and-limits.dic.json';
import checkPolicy from '../../ru/check-policy.dic.json';

export const commonNS = {
  COMMON: commonDictionary,
  INSURANCE_PERIOD_AND_LIMITS: insurancePeriodAndLimits,
  CHECK_POLICY: checkPolicy,
};

export const commonNamespaces = [
  'COMMON',
  'INSURANCE_PERIOD_AND_LIMITS',
  'CHECK_POLICY',
];
