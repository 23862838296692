import { Suspense, forwardRef, useEffect } from 'react';
import type { Ref, FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import type { SmartComponentAdapterProps } from 'smart-components';
import { formInsuranceRisksSchema } from './schema';
import { InsuranceRisksValues } from '@pulse-smart-components-kit/insurance-risks';
import { SmartInsuranceRisks } from '@entities/import-smart-components/smart-insurance-risks';
import { FallbackSkeleton } from '@shared/components';
import { useHandleGetRisks } from './hooks';

export const InsuranceRisksAdapter: FC<
  SmartComponentAdapterProps<InsuranceRisksValues>
> = observer(
  forwardRef(
    (
      { isSubmitting, fieldState, value, onChange },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
        },
      } = useStores();
      const { data } = useHandleGetRisks();

      useEffect(() => {
        if (!data || !data.length) return;

        onChange({
          risks: data,
          isValid: true,
        });
      }, [data]);

      return (
        <Suspense fallback={<FallbackSkeleton height={460} />}>
          <SmartInsuranceRisks
            onChange={onChange}
            value={value}
            ref={forwardRef}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formInsuranceRisksSchema}
            isSubmitting={isSubmitting}
            content={{}}
            onAnalyticEventSend={{}}
            formContext={{}}
            options={{}}
          />
        </Suspense>
      );
    }
  )
);
