type ConvertedSubObjects = {
  code: string;
  insuranceSum: string;
};

export const getSubObjects = (limits?: Record<string, number>) => {
  if (!limits || Object.keys(limits).length === 0) return;

  return Object.entries(limits).reduce<Array<ConvertedSubObjects>>(
    (acc, [key, value]) => {
      if (value === 0) return acc;
      acc.push({
        code: key,
        insuranceSum: String(value),
      });
      return acc;
    },
    []
  );
};
