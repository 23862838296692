import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { WhereAndHowLong } from '@entities/import-smart-components/where-and-how-long';
import type { SmartComponentStoreKey } from '@entities/stores';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';
import { observer } from 'mobx-react-lite';
import { type FC, Suspense, useMemo, forwardRef, type Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WhereAndHowLongOptions,
  WhereAndHowLongValues,
} from 'smart-components';
import { WhereAndHowLongAnalyticEvent } from 'smart-components/where-and-how-long';

const analyticEventsMap: AnalyticEventsMap<WhereAndHowLongAnalyticEvent> = {
  [WhereAndHowLongAnalyticEvent.ON_DATES_SELECT]: {
    name: analyticEvents.travelDatesSelected,
  },
  [WhereAndHowLongAnalyticEvent.ON_COUNTRY_SELECT]: {
    name: analyticEvents.travelCountrySelected,
  },
};

export const WhereAndHowLongAdapter: FC<
  SmartComponentAdapterProps<WhereAndHowLongValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, name, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: {
            insuranceLimitStart: { startDate, endDate },
            initState: { territories },
          },
          authStore: { disableForm },
          smartComponentAdditionalOptionsStore: { additionalOptions },
        },
      } = useStores();

      const additionalOptionsForComponent =
        additionalOptions[name as SmartComponentStoreKey];

      const options: WhereAndHowLongOptions = useMemo(
        () => ({
          ...additionalOptionsForComponent,

          // TODO remove mock
          insurancePeriodShortMax: '',
          insurancePeriodShortMin: '',
          effectiveSinceFranchise: '',
          insurancePeriodOneTimeMax: '',
          insurancePeriodOneTimeMin: '',
          insurancePeriodAnnual: [1],

          territories,
          startDate,
          endDate,
        }),
        [additionalOptionsForComponent, territories, startDate, endDate]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<WhereAndHowLongAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <WhereAndHowLong
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

WhereAndHowLongAdapter.displayName = 'WhereAndHowLongAdapter';
