import { InsuredAmountConfigurationOptions } from '@pulse-smart-components-kit/insured-amount-configuration';

export const prepareLimits = (data?: InsuredAmountConfigurationOptions) => {
  if (!data) return {};
  const limits: Record<string, number> = {};

  for (const option of data.subObjects) {
    limits[option.code] = Number(option.defaultInsuranceSum);
  }

  return limits;
};
