import type { GetSubmitOrderRequest } from '../types';
import { INSURANSE_SUM_DEFAULT } from '../constants';
import { prepareDate } from '@shared/utils';
import { Risk } from '@pulse-smart-components-kit/insurance-risks';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const birthDate = new Date(today);
birthDate.setFullYear(tomorrow.getFullYear() - 30);

export const getOrderData = ({
  formState,
  initState,
  promoCode,
}: {
  formState: any;
  initState: any;
  promoCode: string;
}) => {
  const dateBegin = new Date(formState?.StartDate?.date || new Date());

  const risks = formState?.InsuranceRisks?.risks?.map(
    (risk: Risk) => risk.code
  );

  const subObjects = [];

  let totalInsuranceSum = 0;

  for (const code in formState?.InsurancePeriodAndLimits?.limits) {
    const insuranceSum = formState?.InsurancePeriodAndLimits?.limits[code];

    subObjects.push({
      code,
      insuranceSum: insuranceSum.toString(),
    });

    totalInsuranceSum += insuranceSum;
  }

  if (!totalInsuranceSum) {
    totalInsuranceSum = INSURANSE_SUM_DEFAULT;
  }

  const data: GetSubmitOrderRequest = {
    effectiveSince: prepareDate(dateBegin),
    productCode: initState.code || '',
    contractDuration: formState?.InsurancePeriodAndLimits?.period || 'P3M',
    promoCode,
    insuranceSum: totalInsuranceSum.toString(),

    // TODO remove hardcode
    risks: risks ? [...risks, 'Liability_1'] : [],

    personProperties: {
      subObjects,

      // TODO remove mock
      address: {
        address: 'г Москва кв-л 1, 1, 1, кв 1',
        addressCode: '08dae351-16f7-4abb-ade2-b9f95fc3c467',
        regionCode: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        placeCode: '08dae351-16f7-4abb-ade2-b9f95fc3c467',
        cityCode: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      },
    },
  };

  return data;
};
