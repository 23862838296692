export enum Product {
  HOUSE = 'house',
  APARTMENT = 'apartment',
  NS = 'ns',
  PETS = 'pets',
  PETS_TELEVET_PLUS = 'pets-televet-plus',
  SPORT = 'sport-ns',
  MITE = 'mite',
  TRAVEL = 'travel',
}
