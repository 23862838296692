import { Suspense, forwardRef, useEffect, useState, useCallback } from 'react';
import type { Ref, FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import type { SmartComponentAdapterProps } from 'smart-components';
import type { InsuranceAddressValues } from '@pulse-smart-components-kit/insurance-address';
import InsuranceAddress from '@pulse-smart-components-kit/insurance-address';
import {
  REGIONS_MOCK,
  BOUND_REGION,
  BOUND_SETTLEMENT,
} from './mock/insurance-address-mock';
import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';
import { formInsuranceAddressSchema } from './schema';
// import { SmartInsuranceAddress } from '@entities/import-smart-components/smart-insurance-address';
import { FallbackSkeleton } from '@shared/components';
import type {
  InsuranceAddressOptions,
  RegionType,
} from './insurance-address-adapter.types';
import { Product } from '@shared/constants';
import {
  cityDefaultValue,
  settlementDefaultValue,
  streetDefaultValue,
  houseDefaultValue,
  flatDefaultValue,
} from './insurance-address-adapter.constants';

export const InsuranceAddressAdapter: FC<
  SmartComponentAdapterProps<InsuranceAddressValues>
> = observer(
  forwardRef(
    (
      { isSubmitting, fieldState, value, onChange },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
        },
      } = useStores();

      useEffect(() => {
        onChange({
          isValid: true,
        });
      }, []);

      useEffect(() => {
        onChange({
          isValid: true,
        });
      }, [isSubmitting]);

      useEffect(() => {
        console.log('value', value);
      }, [value]);

      const [dadaValue, setdadaValue] = useState<DaDataAddressSuggestion>();
      const [isCheckHouseStatus, setIsCheckHouseStatus] = useState(false);
      const [selectedRegion, setselectedRegion] = useState<RegionType>();
      const [isSelectedCustomHouse, setIsSelectedCustomHouse] =
        useState<boolean>(false);
      const [isSelectedCustomStreet, setIsSelectedCustomStreet] =
        useState<boolean>(false);
      const [isEmptyStreetSuggestions, setIsEmptyStreetSuggestions] =
        useState<boolean>(false);
      const [isEmptyHouseSuggestions, setIsEmptyHouseSuggestions] =
        useState<any>('');
      const [, setIsHouseNotFound] = useState<boolean>(false);

      const prepareDadate = (bound?: string): DaDataAddressSuggestion => {
        let regionDadataValue = BOUND_REGION.suggestions;

        if (bound === 'region') {
          regionDadataValue = {
            ...regionDadataValue,
            data: {
              ...(regionDadataValue as any)?.data,
              ...cityDefaultValue,
              ...settlementDefaultValue,
              ...streetDefaultValue,
              ...houseDefaultValue,
              ...flatDefaultValue,
            },
          };
        }
        if (bound === 'settlement') {
          regionDadataValue = BOUND_SETTLEMENT.suggestions;
        }

        if (bound === 'street') {
          regionDadataValue = BOUND_SETTLEMENT.suggestions;
        }

        if (bound === 'house') {
          regionDadataValue = BOUND_SETTLEMENT.suggestions;
        }

        if (Array.isArray(regionDadataValue)) {
          regionDadataValue = regionDadataValue[0];
        }
        return regionDadataValue as DaDataAddressSuggestion;
      };

      type DadaCB = {
        query: string;
        from_bound: { value: string };
        to_bound: { value: string };
      };

      const dadataCB = (e?: DadaCB) => {
        setdadaValue(prepareDadate(e?.to_bound.value));
      };

      const OPTIONS: InsuranceAddressOptions = {
        regions: REGIONS_MOCK,
        isCheckHouseStatus,
        isSelectedCustomStreet,
        isSelectedCustomHouse,
        isEmptyHouseSuggestions,
        setIsHouseNotFound,
        setIsCheckHouseStatus,
        setIsEmptyHouseSuggestions,
        setIsSelectedCustomStreet,
        productType: Product.APARTMENT,
        storeSelectedRegion: setselectedRegion,
        selectedRegion,
        isEmptyStreetSuggestions,
        setIsEmptyStreetSuggestions,
        setIsSelectedCustomHouse,
        dadaValue,
        dadataCB,
      };

      const handleOnChange = useCallback(
        (v: any) => {
          onChange({ ...v, isValid: true });
        },
        [onChange]
      );

      void forwardRef, formInsuranceAddressSchema;

      return (
        <Suspense fallback={<FallbackSkeleton height={460} />}>
          <InsuranceAddress
            onChange={handleOnChange}
            value={value}
            ref={forwardRef}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formInsuranceAddressSchema as any}
            isSubmitting={isSubmitting}
            // onAnalyticEventSend={{}}
            // formContext={{}}
            options={OPTIONS}
          />
        </Suspense>
      );
    }
  )
);

InsuranceAddressAdapter.displayName = 'InsuranceAddressAdapter';
