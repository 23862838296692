import { authRequestScheme } from '@shared/scheme';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { AuthRequest, AuthResponse } from './auth.types';

export const useFetchAuth = (phone: string) => {
  const data: AuthRequest = {
    authenticationUser: {
      phoneNumber: phone,
    },
  };

  const fetch = fetchData<AuthResponse>({
    url: '/v1/user/authentication',
    method: 'POST',
    data,
  });

  const queryResult = useQuery(['auth', phone], fetch, {
    enabled: authRequestScheme.isValidSync(data),
  });

  return queryResult;
};
