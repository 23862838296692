import { SmartCheckPolicy } from '@entities/import-smart-components/smart-check-policy';
import type {
  CheckPolicyOptions,
  CheckPolicyValues,
} from '@pulse-smart-components-kit/check-policy';
import { FallbackSkeleton } from '@shared/components';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, FC, useMemo } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';
import { formCheckPolicySchema } from './schema';
import { useHandleDownloadPDF, useStores } from '@shared/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { TERMS_POLICY } from './constants';
import { i18nDefaultValues } from './i18n';
import { Risk } from '@pulse-smart-components-kit/insurance-risks';
import { useHandleGetSubobjects } from '../insurance-period-and-limits-adapter/queries';

export const CheckPolicyAdapter: FC<
  SmartComponentAdapterProps<CheckPolicyValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { paymenStep },
          productStore: { formState, orderData },
          authStore: {
            profile: { profile },
            disableForm,
            accessToken,
          },
        },
      } = useStores();

      const { t } = useTranslation();

      const isAcceptCheckboxDisabled = disableForm || paymenStep;

      const { handleDownloadClick, documentsStatusList } = useHandleDownloadPDF(
        accessToken || '',
        orderData?.clientDocument
      );

      const userFullName = useMemo(() => {
        if (profile) {
          return `${profile.lastName} ${profile.firstName} ${profile.middleName}`;
        }
      }, [profile]);

      const risks = useMemo(() => {
        return formState?.InsuranceRisks?.risks?.map((risk: Risk) => risk.name);
      }, [formState?.InsuranceRisks?.risks]);

      const { data } = useHandleGetSubobjects();

      const [totalInsuranceSum, subObjects] = useMemo(() => {
        const subObjects = [];

        let totalInsuranceSum = 0;

        for (const code in formState?.InsurancePeriodAndLimits?.limits) {
          const insuranceSum =
            formState?.InsurancePeriodAndLimits?.limits[code];

          const subObjectName = data?.subObjects?.find(
            (subObject) => subObject.code === code
          )?.name;

          subObjects.push({
            title: subObjectName || '',
            value: t('CHECK_POLICY:label.productItem.value', {
              summ: insuranceSum.toString(),
            }),
          });

          totalInsuranceSum += insuranceSum;
        }

        return [totalInsuranceSum, subObjects];
      }, []);

      const options: CheckPolicyOptions = useMemo(
        () => ({
          onDownload: handleDownloadClick,
          documentsList: orderData?.clientDocument,
          policyIncludedConfig: {
            coverageSum: totalInsuranceSum,
            subTitle: t('CHECK_POLICY:label.subTitle', {
              coverage: subObjects.length,
              risks: risks?.length,
            }),
            coverageSumTitle: t('CHECK_POLICY:label.coverageSumTitle'),
            productItems: subObjects,
            listTitle: t('CHECK_POLICY:label.listTitle'),
            listItems: risks,
          },
          documentsStatusList,
          insuranceEntitiesConfig: {
            items: [
              {
                title: t('CHECK_POLICY:label.insurerTitle'),
                key: 1,
                description: (
                  <>
                    {userFullName}
                    <br />
                    {profile.email}
                  </>
                ),
              },

              // TODO add an address when logic is ready
              // {
              //   title: 'Адрес квартиры',
              //   key: 2,
              //   description: (
              //     <>Воронежская область, г. Нововоронеж, ул. Весенняя, д. 42, кв. 3</>
              //   ),
              // },

              {
                title: t('CHECK_POLICY:label.startDateTitle'),
                key: 3,
                description: (
                  <>{formState.StartDate?.date?.toLocaleDateString('ru-RU')}</>
                ),
              },
            ],
          },
          acceptTermsConfig: {
            title: (
              <Trans
                components={{
                  AcceptTermsLink: <a />,
                }}
                values={{ href: TERMS_POLICY }}
              >
                {t('SMART:CheckPolicy.labels.acceptTermsPolicy', {
                  defaultValue:
                    i18nDefaultValues.CheckPolicy.labels.acceptTermsPolicy,
                })}
              </Trans>
            ),
          },
        }),
        [
          orderData?.clientDocument,
          documentsStatusList,
          totalInsuranceSum,
          subObjects,
          risks,
        ]
      );

      return (
        <Suspense fallback={<FallbackSkeleton height={460} />}>
          <SmartCheckPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={isAcceptCheckboxDisabled}
            fieldState={fieldState}
            formSchema={formCheckPolicySchema}
            onAnalyticEventSend={() => {
              return;
            }}
          />
        </Suspense>
      );
    }
  )
);

CheckPolicyAdapter.displayName = 'CheckPolicyAdapter';
