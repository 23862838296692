import { Suspense, forwardRef, useMemo } from 'react';
import type { Ref } from 'react';
import { observer } from 'mobx-react-lite';
import type { InsuredAmountConfigurationValues } from '@pulse-smart-components-kit/insured-amount-configuration';
import { WidgetContainer } from '@pulse-web-ui/containers';

import { useStores } from '@shared/hooks';
import { FallbackSkeleton } from '@shared/components';
import { SmartInsuranceAmountConfiguration } from '@entities/import-smart-components/smart-insurance-amount-configuration';
import type { SmartComponentAdapterProps } from 'smart-components';
import { StyledWrapper } from './insurance-period-and-limits-adapter.style';
import { prepareConfigurationOptions } from './utils';
import { useTranslation } from 'react-i18next';
import { useHandleGetSubobjects } from './queries';
import { preparePrices } from './utils/prepare-prices';
import { useWindowSize } from '@shared/hooks';

export const InsurancePeriodAndLimitsAdapter = observer(
  forwardRef<
    HTMLDivElement,
    SmartComponentAdapterProps<InsuredAmountConfigurationValues>
  >(({ isSubmitting, onChange, value }, forwardRef: Ref<HTMLDivElement>) => {
    const {
      MainStore: {
        authStore: { disableForm },
        productStore: { price },
      },
    } = useStores();
    const { t } = useTranslation();

    const { data, isLoading } = useHandleGetSubobjects();

    const [windowWidth] = useWindowSize();

    const pricesOptions = useMemo(() => {
      return preparePrices(windowWidth, price.prices);
    }, [windowWidth, price.prices]);

    const options = useMemo(
      () => ({
        options: prepareConfigurationOptions(data, value),
        subTitle: t('INSURANCE_PERIOD_AND_LIMITS:label.subTitle'),
        title: t('INSURANCE_PERIOD_AND_LIMITS:label.title'),
        defaultPeriod: value?.period ?? price.defaultContractDuration,
        periodOptions: pricesOptions ?? [],
      }),
      [data, value, value?.period, price.defaultContractDuration, pricesOptions]
    );

    if (isLoading || !data || !pricesOptions?.length) {
      return (
        <WidgetContainer>
          <FallbackSkeleton height={820} />
        </WidgetContainer>
      );
    }

    return (
      <StyledWrapper>
        <Suspense fallback={<FallbackSkeleton height={800} />}>
          <SmartInsuranceAmountConfiguration
            disabled={disableForm}
            isSubmitting={isSubmitting}
            onChange={onChange}
            ref={forwardRef}
            options={options}
          />
        </Suspense>
      </StyledWrapper>
    );
  })
);

InsurancePeriodAndLimitsAdapter.displayName = 'InsurancePeriodAndLimitsAdapter';
