/* eslint-disable indent */
import { useQueryFunction } from '@shared/hooks';
import { useQueries } from 'react-query';
import { getQueryKey } from './use-handle-download-pdf.utils';
import { UseMultipleDownloadPdf } from './use-handle-download-pdf.types';

const getQueryFn = (url: string, accessToken: string) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/pdf',
    Authorization: `Bearer ${accessToken}`,
  };

  return useQueryFunction('post', `/v1/file/${url}`, {}, '', {
    responseType: 'arraybuffer',
    headers,
  });
};

export const useMultipleDownloadPdf: UseMultipleDownloadPdf = ({
  documentsList,
  queryEnabledList,
  accessToken,
}) =>
  useQueries(
    documentsList
      ? documentsList.map((doc, index) => ({
          queryKey: [getQueryKey(doc.clientDocument)],
          queryFn: getQueryFn(doc.clientDocument, accessToken),
          enabled: !!queryEnabledList[index],
          staleTime: 0, // TODO не работет, попробовать использовать useQuery вместо axios в рамках рефакторинга сетевого слоя
          cacheTime: 0,
        }))
      : []
  );
