import type { Price } from '@shared/queries';
import {
  PeriodWrapper,
  PeriodBadge,
} from '../insurance-period-and-limits-adapter.style';
import { periodNames, adaptivePeriodNames } from '@shared/constants';

export const preparePrices = (windowWidth: number, prices?: Price[]) => {
  return prices?.map((price) => ({
    value: price.duration,
    label: (
      <PeriodWrapper>
        {windowWidth >= 720
          ? periodNames[price.duration]
          : adaptivePeriodNames[price.duration]}
        {!!Number(price.discount) && (
          <PeriodBadge>-{price.discount}%</PeriodBadge>
        )}
      </PeriodWrapper>
    ),
  }));
};
