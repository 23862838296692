import { boolean, object, string } from 'yup';

export const addressSchema = object().shape({
  code: string().required(),
  name: string().required(),
  active: boolean().required(),
  binding: boolean().required(),
  description: string(),
  imageUrl: string(),
  extendedDescription: string(),
  switchability: boolean(),
});

// TODO: реализовать схему валидации адреса
export const formInsuranceAddressSchema = object().shape({});
