import { POST_MESSAGE_TYPE } from '@shared/constants';

export const postSubmitMessageToDigitalAgent = (message: string) => {
  window.top?.postMessage(
    JSON.stringify({
      message,
      type: POST_MESSAGE_TYPE,
    }),
    window.envUrls.AGENT_URL
  );
};
