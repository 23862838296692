import { useStores } from '@shared/hooks';
import { Endpoints } from '@shared/constants';
import { useQuery } from 'react-query';
import { fetchData } from '@shared/utils';
import type { RisksResponse } from '../insurance-risks-adapter.types';

export const useHandleGetRisks = () => {
  const {
    MainStore: {
      initProductStore: { setInitState, initState },
    },
  } = useStores();

  const { refetch, isLoading } = useQuery(
    'formIFLGetRisks',
    fetchData<RisksResponse>({
      url: Endpoints.GET_RISKS,
      method: 'post',
      data: {
        productCode: initState.code,
      },
    }),
    {
      enabled: !!initState.code,
      onSuccess(data) {
        const filteredRisks = data.risks.filter(
          (risk) => risk.code !== 'Liability_1'
        );

        setInitState({ ...initState, ...{ risks: filteredRisks } });
      },
    }
  );

  return { refetch, data: initState.risks, isLoading };
};
