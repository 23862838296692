/* eslint-disable indent */
import { RisksOptionsType } from '@shared/types';
import { useEffect, useMemo, useState } from 'react';
import { pluralization } from '@shared/utils';
import { useTranslation } from 'react-i18next';

export const useGetRisksOptionsLength = (
  fullRisksOptionsLength: number,
  fullOptionsRisks: RisksOptionsType[]
) => {
  const [riskOptionsLength, setRisksOptionsLength] = useState(
    fullRisksOptionsLength
  );

  const { t } = useTranslation();

  const serviceDeclensions = useMemo(
    () => [
      t('COMMON:declensions.service'),
      t('COMMON:declensions.service2'),
      t('COMMON:declensions.service3'),
    ],
    []
  );

  const optionDeclensions = useMemo(
    () => [
      t('COMMON:declensions.option'),
      t('COMMON:declensions.option2'),
      t('COMMON:declensions.option3'),
    ],
    []
  );

  useEffect(() => {
    setRisksOptionsLength(fullRisksOptionsLength);
  }, [fullRisksOptionsLength]);

  useEffect(() => {
    if (fullOptionsRisks) {
      const checkedOptionsRisksLength = fullOptionsRisks.filter(
        (values: any) => values.checked
      ).length;

      const optionsRisksFinalLength =
        fullRisksOptionsLength + checkedOptionsRisksLength;
      setRisksOptionsLength(optionsRisksFinalLength);
    }
  }, [fullOptionsRisks, fullRisksOptionsLength]);

  const formattedOptionsString = `${
    riskOptionsLength
      ? `${riskOptionsLength} ${pluralization(
          riskOptionsLength,
          optionDeclensions
        )}`
      : `${0} ${optionDeclensions[2]}`
  }`;

  const formattedOptionsStringFooter = `${
    riskOptionsLength
      ? `${riskOptionsLength} ${pluralization(
          riskOptionsLength,
          serviceDeclensions
        )}`
      : `${0} ${serviceDeclensions[2]}`
  }`;

  return {
    riskOptionsLength,
    formattedOptionsString,
    formattedOptionsStringFooter,
  };
};
