export const i18nDefaultValues = {
  WhoIssuesPolicy: {
    title: 'Страхователь',
    subTitle: 'Тот, кто покупает страховку',
    labels: {
      phone: 'Телефон',
      client: 'Я уже ваш клиент',
      surname: 'Фамилия',
      name: 'Имя',
      middleName: 'Отчество',
      birthDate: 'Дата рождения',
      email: 'Email',
    },
    hints: {
      middleName: 'Если у вас нет отчества, оставьте поле пустым',
      phone: 'На него мы отправим проверочный код',
      sendByMail: 'На эту почту мы направим полис',
    },
    errors: {
      notFilled: 'Поле не заполнено',
      maxLength: 'Максимальная длина 50 символов',
      useCyrillic: 'Используйте кириллицу',
      nameStartWithLetter: 'Имя должно начинаться с буквы',
      lastnameStartWithLetter: 'Фамилия должна начинаться с буквы',
      middleNameStartWithLetter: 'Отчество должно начинаться с буквы',
      incorrectEmail: 'Некорректный адрес электронной почты',
      enterEmail: 'Укажите электронную почту',
      insuranceFromAgeEighteen: 'Страховку можно оформить только с 18 лет',
      enterPhone: 'Введите номер телефона',
      wrongPhone: 'Неверный номер телефона',
    },
  },
};
