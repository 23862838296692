import i18n from '@app/i18n/config';
import type { SelectedDuration } from '@shared/types';

export const periodNames: Record<SelectedDuration, string> = {
  P1M: i18n.t('COMMON:periods.oneMonth'),
  P3M: i18n.t('COMMON:periods.threeMonths'),
  P1Y: i18n.t('COMMON:periods.oneYear'),
} as const;

export const adaptivePeriodNames: Record<SelectedDuration, string> = {
  P1M: i18n.t('COMMON:adaptivePeriods.oneMonth'),
  P3M: i18n.t('COMMON:adaptivePeriods.threeMonths'),
  P1Y: i18n.t('COMMON:adaptivePeriods.oneYear'),
} as const;

export const footerPeriodNames: Record<SelectedDuration, string> = {
  P1M: i18n.t('COMMON:footerPeriods.oneMonth'),
  P3M: i18n.t('COMMON:footerPeriods.threeMonths'),
  P1Y: i18n.t('COMMON:footerPeriods.oneYear'),
} as const;
