/* eslint-disable @typescript-eslint/no-explicit-any */
import { boolean, date, object, string } from 'yup';
import {
  maxLength,
  onlyCyrillic,
  onlyLetters,
  phoneRegEx,
  emailRegEx,
  promoEx,
} from '@shared/constants';

import { phoneValueCasting } from '@shared/utils';

const defaultFioSchema = string()
  .required('COMMON:errors.notFilled')
  .test('maxLength', 'COMMON:errors.maxLength', (value) =>
    maxLength.test(value!)
  );

const firstNameSchema = defaultFioSchema
  .test('onlyLetters', 'COMMON:errors.nameStartWithLetter', (value) =>
    onlyLetters.test(value!)
  )
  .test('onlyCyrillic', 'COMMON:errors.useCyrillic', (value) =>
    onlyCyrillic.test(value!)
  );

const lastNameSchema = defaultFioSchema
  .test('onlyLetters', 'COMMON:errors.lastnameStartWithLetter', (value) =>
    onlyLetters.test(value!)
  )
  .test('onlyCyrillic', 'COMMON:errors.useCyrillic', (value) =>
    onlyCyrillic.test(value!)
  );

export const profileFormSchema = object().shape(
  {
    name: firstNameSchema,
    surname: lastNameSchema,
    secondName: string()
      .nullable()
      .nonNullable()
      .notRequired()
      .when({
        is: (value: string | any[]) => value?.length,
        then: () =>
          defaultFioSchema
            .test(
              'onlyLetters',
              'COMMON:errors.middleNameStartWithLetter',
              (value) => onlyLetters.test(value!)
            )
            .test('onlyCyrillic', 'COMMON:errors.useCyrillic', (value) =>
              onlyCyrillic.test(value!)
            ),
      }),
  },
  [['secondName', 'secondName']]
);

export const getPhoneValidationSchema = (agentLogin?: string) =>
  string()
    .required('COMMON:errors.enterPhone')
    .test('validPhone', 'COMMON:errors.wrongPhone', (value) =>
      phoneRegEx.test(value as string)
    )
    .test('isAgentPhone', 'COMMON:errors.numberMatchesAgent', (value) =>
      agentLogin ? phoneValueCasting(value) !== agentLogin : true
    );

export const getFormInsuranceContactsSchema = (agentLogin?: string) =>
  profileFormSchema.shape({
    email: string()
      .nullable()
      .required('COMMON:errors.enterEmail')
      .test(
        'validEmail',
        'COMMON:errors.incorrectEmail',
        (value) => !!value && emailRegEx.test(value)
      ),
    birthDate: date().required('COMMON:errors.notFilled'),
    client: boolean(),
    hasAuthorized: boolean().notRequired(),
    promoAgreement: boolean().notRequired(),
    phone: getPhoneValidationSchema(agentLogin),
    showSwitcher: string().notRequired(),
    isValid: boolean(),
  });

export const formInsurancePeriodSchema = object().shape({
  promoCode: string()
    .optional()
    .test('validPromo', 'COMMON:errors.invalidPromoCode', (value) => {
      return !value?.length || promoEx.test(value);
    }),
});
