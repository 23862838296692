import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import type { RegionType } from '../insurance-address-adapter.types';

export const REGIONS_MOCK: RegionType[] = [
  {
    region: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    regionName: 'город Москва',
  },
  {
    region: '29251dcf-00a1-4e34-98d4-5c47484a36d4',
    regionName: 'Московская область',
  },
  {
    region: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    regionName: 'Санкт-Петербург',
  },
  {
    region: '6d1ebb35-70c6-4129-bd55-da3969658f5d',
    regionName: 'Ленинградская область',
  },
  {
    region: '8276c6a1-1a86-4f0d-8920-aba34d4cc34a',
    regionName: 'Алтайский край',
  },
  {
    region: '844a80d6-5e31-4017-b422-4d9c01e9942c',
    regionName: 'Амурская область',
  },
  {
    region: '294277aa-e25d-428c-95ad-46719c4ddb44',
    regionName: 'Архангельская область',
  },
  {
    region: '83009239-25cb-4561-af8e-7ee111b1cb73',
    regionName: 'Астраханская область',
  },
  {
    region: 'b8837188-39ee-4ff9-bc91-fcc9ed451bb3',
    regionName: 'Владимирская область',
  },
  {
    region: 'da051ec8-da2e-4a66-b542-473b8d221ab4',
    regionName: 'Волгоградская область',
  },
  {
    region: 'ed36085a-b2f5-454f-b9a9-1c9a678ee618',
    regionName: 'Вологодская область',
  },
  {
    region: 'b756fe6b-bbd3-44d5-9302-5bfcc740f46e',
    regionName: 'Воронежская область',
  },
  {
    region: 'b6ba5716-eb48-401b-8443-b197c9578734',
    regionName: 'Забайкальский край',
  },
  {
    region: '0824434f-4098-4467-af72-d4f702fed335',
    regionName: 'Ивановская область',
  },
  {
    region: '6466c988-7ce3-45e5-8b97-90ae16cb1249',
    regionName: 'Иркутская область',
  },
  {
    region: '1781f74e-be4a-4697-9c6b-493057c94818',
    regionName: 'Кабардино-Балкарская Республика',
  },
  {
    region: '90c7181e-724f-41b3-b6c6-bd3ec7ae3f30',
    regionName: 'Калининградская область',
  },
  {
    region: '18133adf-90c2-438e-88c4-62c41656de70',
    regionName: 'Калужская область',
  },
  {
    region: 'd02f30fc-83bf-4c0f-ac2b-5729a866a207',
    regionName: 'Камчатский край',
  },
  {
    region: '61b95807-388a-4cb1-9bee-889f7cf811c8',
    regionName: 'Карачаево-Черкесская Республика',
  },
  {
    region: '393aeccb-89ef-4a7e-ae42-08d5cebc2e30',
    regionName: 'Кемеровская область - Кузбасс',
  },
  {
    region: '0b940b96-103f-4248-850c-26b6c7296728',
    regionName: 'Кировская область',
  },
  {
    region: '15784a67-8cea-425b-834a-6afe0e3ed61c',
    regionName: 'Костромская область',
  },
  {
    region: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
    regionName: 'Краснодарский край',
  },
  {
    region: 'db9c4f8b-b706-40e2-b2b4-d31b98dcd3d1',
    regionName: 'Красноярский край',
  },
  {
    region: '4a3d970f-520e-46b9-b16c-50d4ca7535a8',
    regionName: 'Курганская область',
  },
  {
    region: '1490490e-49c5-421c-9572-5673ba5d80c8',
    regionName: 'Липецкая область',
  },
  {
    region: '9c05e812-8679-4710-b8cb-5e8bd43cdf48',
    regionName: 'Магаданская область',
  },
  {
    region: '1c727518-c96a-4f34-9ae6-fd510da3be03',
    regionName: 'Мурманская область',
  },
  {
    region: '89db3198-6803-4106-9463-cbf781eff0b8',
    regionName: 'Ненецкий автономный округ',
  },
  {
    region: '88cd27e2-6a8a-4421-9718-719a28a0a088',
    regionName: 'Нижегородская область',
  },
  {
    region: 'e5a84b81-8ea1-49e3-b3c4-0528651be129',
    regionName: 'Новгородская область',
  },
  {
    region: '1ac46b49-3209-4814-b7bf-a509ea1aecd9',
    regionName: 'Новосибирская область',
  },
  {
    region: '05426864-466d-41a3-82c4-11e61cdc98ce',
    regionName: 'Омская область',
  },
  {
    region: '8bcec9d6-05bc-4e53-b45c-ba0c6f3a5c44',
    regionName: 'Оренбургская область',
  },
  {
    region: '5e465691-de23-4c4e-9f46-f35a125b5970',
    regionName: 'Орловская область',
  },
  {
    region: 'c99e7924-0428-4107-a302-4fd7c0cca3ff',
    regionName: 'Пензенская область',
  },
  {
    region: '4f8b1a21-e4bb-422f-9087-d3cbf4bebc14',
    regionName: 'Пермский край',
  },
  {
    region: '43909681-d6e1-432d-b61f-ddac393cb5da',
    regionName: 'Приморский край',
  },
  {
    region: 'f6e148a1-c9d0-4141-a608-93e3bd95e6c4',
    regionName: 'Псковская область',
  },
  {
    region: 'd8327a56-80de-4df2-815c-4f6ab1224c50',
    regionName: 'Республика Адыгея (Адыгея)',
  },
  {
    region: '5c48611f-5de6-4771-9695-7e36a4e7529d',
    regionName: 'Республика Алтай',
  },
  {
    region: '6f2cbfd8-692a-4ee4-9b16-067210bde3fc',
    regionName: 'Республика Башкортостан',
  },
  {
    region: 'a84ebed3-153d-4ba9-8532-8bdf879e1f5a',
    regionName: 'Республика Бурятия',
  },
  {
    region: '0bb7fa19-736d-49cf-ad0e-9774c4dae09b',
    regionName: 'Республика Дагестан',
  },
  {
    region: 'b2d8cd20-cabc-4deb-afad-f3c4b4d55821',
    regionName: 'Республика Ингушетия',
  },
  {
    region: '491cde9d-9d76-4591-ab46-ea93c079e686',
    regionName: 'Республика Калмыкия',
  },
  {
    region: '248d8071-06e1-425e-a1cf-d1ff4c4a14a8',
    regionName: 'Республика Карелия',
  },
  {
    region: 'c20180d9-ad9c-46d1-9eff-d60bc424592a',
    regionName: 'Республика Коми',
  },
  {
    region: 'de2cbfdf-9662-44a4-a4a4-8ad237ae4a3e',
    regionName: 'Республика Марий Эл',
  },
  {
    region: '37a0c60a-9240-48b5-a87f-0d8c86cdb6e1',
    regionName: 'Республика Мордовия',
  },
  {
    region: 'c225d3db-1db6-4063-ace0-b3fe9ea3805f',
    regionName: 'Республика Саха (Якутия)',
  },
  {
    region: 'de459e9c-2933-4923-83d1-9c64cfd7a817',
    regionName: 'Республика Северная Осетия — Алания',
  },
  {
    region: '026bc56f-3731-48e9-8245-655331f596c0',
    regionName: 'Республика Тыва',
  },
  {
    region: '8d3f1d35-f0f4-41b5-b5b7-e7cadf3e7bd7',
    regionName: 'Республика Хакасия',
  },
  {
    region: '963073ee-4dfc-48bd-9a70-d2dfc6bd1f31',
    regionName: 'Рязанская область',
  },
  {
    region: 'df3d7359-afa9-4aaa-8ff9-197e73906b1c',
    regionName: 'Самарская область',
  },
  {
    region: 'df594e0e-a935-4664-9d26-0bae13f904fe',
    regionName: 'Саратовская область',
  },
  {
    region: 'aea6280f-4648-460f-b8be-c2bc18923191',
    regionName: 'Сахалинская область',
  },
  {
    region: '92b30014-4d52-4e2e-892d-928142b924bf',
    regionName: 'Свердловская область',
  },
  {
    region: '6fdecb78-893a-4e3f-a5ba-aa062459463b',
    regionName: 'Севастополь',
  },
  {
    region: 'e8502180-6d08-431b-83ea-c7038f0df905',
    regionName: 'Смоленская область',
  },
  {
    region: '327a060b-878c-4fb4-8dc4-d5595871a3d8',
    regionName: 'Ставропольский край',
  },
  {
    region: 'a9a71961-9363-44ba-91b5-ddf0463aebc2',
    regionName: 'Тамбовская область',
  },
  {
    region: '0c089b04-099e-4e0e-955a-6bf1ce525f1a',
    regionName: 'Татарстан',
  },
  {
    region: '61723327-1c20-42fe-8dfa-402638d9b396',
    regionName: 'Тверская область',
  },
  {
    region: '889b1f3a-98aa-40fc-9d3d-0f41192758ab',
    regionName: 'Томская область',
  },
  {
    region: 'd028ec4f-f6da-4843-ada6-b68b3e0efa3d',
    regionName: 'Тульская область',
  },
  {
    region: '54049357-326d-4b8f-b224-3c6dc25d6dd3',
    regionName: 'Тюменская область',
  },
  {
    region: '52618b9c-bcbb-47e7-8957-95c63f0b17cc',
    regionName: 'Удмуртская Республика',
  },
  {
    region: 'fee76045-fe22-43a4-ad58-ad99e903bd58',
    regionName: 'Ульяновская область',
  },
  {
    region: '7d468b39-1afa-41ec-8c4f-97a8603cb3d4',
    regionName: 'Хабаровский край',
  },
  {
    region: 'd66e5325-3a25-4d29-ba86-4ca351d9704b',
    regionName: 'Ханты-Мансийский автономный округ — Югра',
  },
  {
    region: '27eb7c10-a234-44da-a59c-8b1f864966de',
    regionName: 'Челябинская область',
  },
  {
    region: 'de67dc49-b9ba-48a3-a4cc-c2ebfeca6c5e',
    regionName: 'Чеченская Республика',
  },
  {
    region: '878fc621-3708-46c7-a97f-5a13a4176b3e',
    regionName: 'Чувашская Республика — Чувашия',
  },
  {
    region: 'f136159b-404a-4f1f-8d8d-d169e1374d5c',
    regionName: 'Чукотский автономный округ',
  },
  {
    region: '826fa834-3ee8-404f-bdbc-13a5221cfb6e',
    regionName: 'Ямало-Ненецкий автономный округ',
  },
  {
    region: 'a84b2ef4-db03-474b-b552-6229e801ae9b',
    regionName: 'Ярославская область',
  },
];

export const BOUND_REGION: {
  suggestions: DaDataAddressSuggestion[] | DaDataAddressSuggestion;
} = {
  suggestions: {
    value: 'г Москва',
    unrestricted_value: '101000, г Москва',
    data: {
      postal_code: '101000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Центральный',
      region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      region_kladr_id: '7700000000000',
      region_iso_code: 'RU-MOW',
      region_with_type: 'г Москва',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Москва',
      area_fias_id: '',
      area_kladr_id: '',
      area_with_type: '',
      area_type: '',
      area_type_full: '',
      area: '',
      city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      city_kladr_id: '7700000000000',
      city_with_type: 'г Москва',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Москва',
      city_area: '',
      city_district_fias_id: '',
      city_district_kladr_id: '',
      city_district_with_type: '',
      city_district_type: '',
      city_district_type_full: '',
      city_district: '',
      settlement_fias_id: '',
      settlement_kladr_id: '',
      settlement_with_type: '',
      settlement_type: '',
      settlement_type_full: '',
      settlement: '',
      street_fias_id: '',
      street_kladr_id: '',
      street_with_type: '',
      street_type: '',
      street_type_full: '',
      street: '',
      stead_fias_id: '',
      stead_cadnum: null,
      stead_type: '',
      stead_type_full: '',
      stead: '',
      house_fias_id: '',
      house_kladr_id: '',
      house_cadnum: null,
      house_type: '',
      house_type_full: '',
      house: '',
      block_type: '',
      block_type_full: '',
      block: '',
      entrance: null,
      floor: null,
      flat_fias_id: '',
      flat_cadnum: null,
      flat_type: '',
      flat_type_full: '',
      flat: '',
      flat_area: '',
      square_meter_price: '',
      flat_price: null,
      postal_box: '',
      fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      fias_code: '',
      fias_level: '1',
      fias_actuality_state: '0',
      kladr_id: '7700000000000',
      geoname_id: '524901',
      capital_marker: '0',
      okato: '45000000000',
      oktmo: '45000000',
      tax_office: '7700',
      tax_office_legal: '7700',
      timezone: '',
      geo_lat: '55.75396',
      geo_lon: '37.620393',
      beltway_hit: null,
      beltway_distance: '',
      metro: null,
      divisions: '',
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: '',
      qc: null,
    },
  },
};

export const BOUND_SETTLEMENT: {
  suggestions: DaDataAddressSuggestion[] | DaDataAddressSuggestion;
} = {
  suggestions: [
    {
      value: 'г Москва',
      unrestricted_value: '101000, г Москва',
      data: {
        postal_code: '101000',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: '',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        fias_code: '',
        fias_level: '1',
        fias_actuality_state: '0',
        kladr_id: '7700000000000',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45000000000',
        oktmo: '45000000',
        tax_office: '7700',
        tax_office_legal: '7700',
        timezone: '',
        geo_lat: '55.75396',
        geo_lon: '37.620393',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '4',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Зеленоград',
      unrestricted_value: '124365, г Москва, г Зеленоград',
      data: {
        postal_code: '124365',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: 'ec44c0ee-bf24-41c8-9e1c-76136ab05cbf',
        city_kladr_id: '7700000200000',
        city_with_type: 'г Зеленоград',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Зеленоград',
        city_area: '',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: 'ec44c0ee-bf24-41c8-9e1c-76136ab05cbf',
        fias_code: '',
        fias_level: '4',
        fias_actuality_state: '0',
        kladr_id: '7700000200000',
        geoname_id: '463829',
        capital_marker: '0',
        okato: '45000000000',
        oktmo: '45000000',
        tax_office: '7735',
        tax_office_legal: '7735',
        timezone: '',
        geo_lat: '55.991886',
        geo_lon: '37.214386',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '4',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Троицк',
      unrestricted_value: '108840, г Москва, г Троицк',
      data: {
        postal_code: '108840',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '7dde11f6-f6ab-4a05-8052-78e0cab8fc59',
        city_kladr_id: '7700000500000',
        city_with_type: 'г Троицк',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Троицк',
        city_area: 'Троицкий',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '7dde11f6-f6ab-4a05-8052-78e0cab8fc59',
        fias_code: '',
        fias_level: '4',
        fias_actuality_state: '0',
        kladr_id: '7700000500000',
        geoname_id: '481608',
        capital_marker: '0',
        okato: '45298578001',
        oktmo: '45931000',
        tax_office: '7751',
        tax_office_legal: '7751',
        timezone: '',
        geo_lat: '55.484561',
        geo_lon: '37.306764',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '4',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Щербинка',
      unrestricted_value: '108831, г Москва, г Щербинка',
      data: {
        postal_code: '108831',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: 'b7fc1d20-41d4-44e8-af34-d635f452d74c',
        city_kladr_id: '7700000300000',
        city_with_type: 'г Щербинка',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Щербинка',
        city_area: 'Новомосковский',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: 'b7fc1d20-41d4-44e8-af34-d635f452d74c',
        fias_code: '',
        fias_level: '4',
        fias_actuality_state: '0',
        kladr_id: '7700000300000',
        geoname_id: '495260',
        capital_marker: '0',
        okato: '45297581001',
        oktmo: '45932000',
        tax_office: '7751',
        tax_office_legal: '7751',
        timezone: '',
        geo_lat: '55.508743',
        geo_lon: '37.563314',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '4',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Москва, мкр Северное Чертаново',
      unrestricted_value:
        '117648, г Москва, Чертаново Северное р-н, мкр Северное Чертаново',
      data: {
        postal_code: '117648',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Южный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '1343dbe3-1693-4190-8315-a9678f7de4e1',
        settlement_kladr_id: '77000000000790400',
        settlement_with_type: 'мкр Северное Чертаново',
        settlement_type: 'мкр',
        settlement_type_full: 'микрорайон',
        settlement: 'Северное Чертаново',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '1343dbe3-1693-4190-8315-a9678f7de4e1',
        fias_code: '',
        fias_level: '65',
        fias_actuality_state: '0',
        kladr_id: '77000000000790400',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45296593000',
        oktmo: '45924000',
        tax_office: '7726',
        tax_office_legal: '7726',
        timezone: '',
        geo_lat: '55.636412',
        geo_lon: '37.594917',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '3',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'поселение Московский, г Московский',
      unrestricted_value:
        '108811, г Москва, поселение Московский, г Московский',
      data: {
        postal_code: '108811',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '762758bb-18b9-440f-bc61-8e1e77ff3fd8',
        area_kladr_id: '7701100000000',
        area_with_type: 'поселение Московский',
        area_type: 'п',
        area_type_full: 'поселение',
        area: 'Московский',
        city_fias_id: 'fbcf1fff-1d7c-445e-ad92-b71c08b8aba3',
        city_kladr_id: '7701100200000',
        city_with_type: 'г Московский',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Московский',
        city_area: 'Новомосковский',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: 'fbcf1fff-1d7c-445e-ad92-b71c08b8aba3',
        fias_code: '',
        fias_level: '4',
        fias_actuality_state: '0',
        kladr_id: '7701100200000',
        geoname_id: '857690',
        capital_marker: '0',
        okato: '45297565001',
        oktmo: '45952000',
        tax_office: '7751',
        tax_office_legal: '7751',
        timezone: '',
        geo_lat: '55.602144',
        geo_lon: '37.346551',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '4',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Москва, Академический р-н',
      unrestricted_value: 'г Москва, Академический р-н',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: '',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: 'Академический р-н',
        city_district_type: 'р-н',
        city_district_type_full: 'район',
        city_district: 'Академический',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        fias_code: '',
        fias_level: '1',
        fias_actuality_state: '0',
        kladr_id: '7700000000000',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45293554000',
        oktmo: '45000000',
        tax_office: '7700',
        tax_office_legal: '7700',
        timezone: '',
        geo_lat: '',
        geo_lon: '',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '5',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Москва, Алексеевский р-н',
      unrestricted_value: 'г Москва, Алексеевский р-н',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: '',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: 'Алексеевский р-н',
        city_district_type: 'р-н',
        city_district_type_full: 'район',
        city_district: 'Алексеевский',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        fias_code: '',
        fias_level: '1',
        fias_actuality_state: '0',
        kladr_id: '7700000000000',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45280552000',
        oktmo: '45000000',
        tax_office: '7700',
        tax_office_legal: '7700',
        timezone: '',
        geo_lat: '',
        geo_lon: '',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '5',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Москва, Алтуфьевский р-н',
      unrestricted_value: 'г Москва, Алтуфьевский р-н',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: '',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: 'Алтуфьевский р-н',
        city_district_type: 'р-н',
        city_district_type_full: 'район',
        city_district: 'Алтуфьевский',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        fias_code: '',
        fias_level: '1',
        fias_actuality_state: '0',
        kladr_id: '7700000000000',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45280554000',
        oktmo: '45000000',
        tax_office: '7700',
        tax_office_legal: '7700',
        timezone: '',
        geo_lat: '',
        geo_lon: '',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '5',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'г Москва, р-н Арбат',
      unrestricted_value: 'г Москва, р-н Арбат',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: '',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: 'р-н Арбат',
        city_district_type: 'р-н',
        city_district_type_full: 'район',
        city_district: 'Арбат',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '',
        street_kladr_id: '',
        street_with_type: '',
        street_type: '',
        street_type_full: '',
        street: '',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        fias_code: '',
        fias_level: '1',
        fias_actuality_state: '0',
        kladr_id: '7700000000000',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45286552000',
        oktmo: '45000000',
        tax_office: '7700',
        tax_office_legal: '7700',
        timezone: '',
        geo_lat: '',
        geo_lon: '',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '5',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
  ],
};

export const BOUND_STREET_1: {
  suggestions: DaDataAddressSuggestion[] | DaDataAddressSuggestion;
} = {
  suggestions: [
    {
      value: 'Машкинское шоссе',
      unrestricted_value: 'г Москва, Машкинское шоссе',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Северный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: 'dd3c2aeb-bc69-47ae-8295-f46e2da2bc60',
        street_kladr_id: '77000000000371600',
        street_with_type: 'Машкинское шоссе',
        street_type: 'ш',
        street_type_full: 'шоссе',
        street: 'Машкинское',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: 'dd3c2aeb-bc69-47ae-8295-f46e2da2bc60',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000371600',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45000000000',
        oktmo: '45000000',
        tax_office: '7733',
        tax_office_legal: '7733',
        timezone: '',
        geo_lat: '55.910685',
        geo_lon: '37.375881',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'Международное шоссе',
      unrestricted_value: 'г Москва, Молжаниновский р-н, Международное шоссе',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Северный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: 'bca407c3-1c3a-4345-9e86-a2d209ba1840',
        street_kladr_id: '77000000000183400',
        street_with_type: 'Международное шоссе',
        street_type: 'ш',
        street_type_full: 'шоссе',
        street: 'Международное',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: 'bca407c3-1c3a-4345-9e86-a2d209ba1840',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000183400',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45277584000',
        oktmo: '45343000',
        tax_office: '7743',
        tax_office_legal: '7743',
        timezone: '',
        geo_lat: '55.937346',
        geo_lon: '37.404258',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'Мещерский пр-кт',
      unrestricted_value: '119618, г Москва, р-н Солнцево, Мещерский пр-кт',
      data: {
        postal_code: '119618',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Западный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '2c571018-806f-4b93-a3f8-182e6eac49ef',
        street_kladr_id: '77000000000184800',
        street_with_type: 'Мещерский пр-кт',
        street_type: 'пр-кт',
        street_type_full: 'проспект',
        street: 'Мещерский',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '2c571018-806f-4b93-a3f8-182e6eac49ef',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000184800',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45268588000',
        oktmo: '45326000',
        tax_office: '7729',
        tax_office_legal: '7729',
        timezone: '',
        geo_lat: '55.67097',
        geo_lon: '37.415595',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'пр-кт Мира',
      unrestricted_value: 'г Москва, пр-кт Мира',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Северо-восточный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '3caaf7f1-9b17-457b-93b2-b8afe9e9aa98',
        street_kladr_id: '77000000000185600',
        street_with_type: 'пр-кт Мира',
        street_type: 'пр-кт',
        street_type_full: 'проспект',
        street: 'Мира',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '3caaf7f1-9b17-457b-93b2-b8afe9e9aa98',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000185600',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45000000000',
        oktmo: '45000000',
        tax_office: '7700',
        tax_office_legal: '7700',
        timezone: '',
        geo_lat: '55.812368',
        geo_lon: '37.637937',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'Мичуринский пр-кт',
      unrestricted_value: 'г Москва, Мичуринский пр-кт',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Западный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '0f072841-643f-4081-baf0-b16760fede91',
        street_kladr_id: '77000000000188000',
        street_with_type: 'Мичуринский пр-кт',
        street_type: 'пр-кт',
        street_type_full: 'проспект',
        street: 'Мичуринский',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '0f072841-643f-4081-baf0-b16760fede91',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000188000',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45000000000',
        oktmo: '45000000',
        tax_office: '7729',
        tax_office_legal: '7729',
        timezone: '',
        geo_lat: '55.693036',
        geo_lon: '37.490459',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'Можайское шоссе',
      unrestricted_value: 'г Москва, Можайский р-н, Можайское шоссе',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Западный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: 'cb2a1efe-dcee-4f0f-9d4c-5a703b9550f6',
        street_kladr_id: '77000000000188700',
        street_with_type: 'Можайское шоссе',
        street_type: 'ш',
        street_type_full: 'шоссе',
        street: 'Можайское',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: 'cb2a1efe-dcee-4f0f-9d4c-5a703b9550f6',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000188700',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45268569000',
        oktmo: '45321000',
        tax_office: '7731',
        tax_office_legal: '7731',
        timezone: '',
        geo_lat: '55.718335',
        geo_lon: '37.415006',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'Московский пр-кт',
      unrestricted_value: '105187, г Москва, р-н Измайлово, Московский пр-кт',
      data: {
        postal_code: '105187',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Восточный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: 'e9f5748b-0d9f-472a-ab6f-09b456b24fd9',
        street_kladr_id: '77000000000190600',
        street_with_type: 'Московский пр-кт',
        street_type: 'пр-кт',
        street_type_full: 'проспект',
        street: 'Московский',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: 'e9f5748b-0d9f-472a-ab6f-09b456b24fd9',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000190600',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45263570000',
        oktmo: '45307000',
        tax_office: '7719',
        tax_office_legal: '7719',
        timezone: '',
        geo_lat: '55.776755',
        geo_lon: '37.756263',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'пр-кт Защитников Москвы',
      unrestricted_value:
        '111677, г Москва, р-н Некрасовка, пр-кт Защитников Москвы',
      data: {
        postal_code: '111677',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Юго-восточный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '061e1972-a40e-4f5d-a872-cefeba14eae7',
        street_kladr_id: '77000000000745100',
        street_with_type: 'пр-кт Защитников Москвы',
        street_type: 'пр-кт',
        street_type_full: 'проспект',
        street: 'Защитников Москвы',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '061e1972-a40e-4f5d-a872-cefeba14eae7',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000745100',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45290574000',
        oktmo: '45391000',
        tax_office: '7721',
        tax_office_legal: '7721',
        timezone: '',
        geo_lat: '55.700253',
        geo_lon: '37.920062',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'пр-кт Маршала Жукова',
      unrestricted_value: 'г Москва, пр-кт Маршала Жукова',
      data: {
        postal_code: '',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Северо-западный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '9e722ecb-da00-452a-b3b5-51b7bc208432',
        street_kladr_id: '77000000000705500',
        street_with_type: 'пр-кт Маршала Жукова',
        street_type: 'пр-кт',
        street_type_full: 'проспект',
        street: 'Маршала Жукова',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '9e722ecb-da00-452a-b3b5-51b7bc208432',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000705500',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45000000000',
        oktmo: '45000000',
        tax_office: '7734',
        tax_office_legal: '7734',
        timezone: '',
        geo_lat: '55.775945',
        geo_lon: '37.442536',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: ['Новохорошевское шоссе'],
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
    {
      value: 'ул Магаданская',
      unrestricted_value:
        '129345, г Москва, Лосиноостровский р-н, ул Магаданская',
      data: {
        postal_code: '129345',
        country: 'Россия',
        country_iso_code: 'RU',
        federal_district: 'Центральный',
        region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        region_kladr_id: '7700000000000',
        region_iso_code: 'RU-MOW',
        region_with_type: 'г Москва',
        region_type: 'г',
        region_type_full: 'город',
        region: 'Москва',
        area_fias_id: '',
        area_kladr_id: '',
        area_with_type: '',
        area_type: '',
        area_type_full: '',
        area: '',
        city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city_kladr_id: '7700000000000',
        city_with_type: 'г Москва',
        city_type: 'г',
        city_type_full: 'город',
        city: 'Москва',
        city_area: 'Северо-восточный',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_with_type: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district: '',
        settlement_fias_id: '',
        settlement_kladr_id: '',
        settlement_with_type: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement: '',
        street_fias_id: '8b930aeb-e13a-49a6-bc74-7d1bd1a965ac',
        street_kladr_id: '77000000000176200',
        street_with_type: 'ул Магаданская',
        street_type: 'ул',
        street_type_full: 'улица',
        street: 'Магаданская',
        stead_fias_id: '',
        stead_cadnum: null,
        stead_type: '',
        stead_type_full: '',
        stead: '',
        house_fias_id: '',
        house_kladr_id: '',
        house_cadnum: null,
        house_type: '',
        house_type_full: '',
        house: '',
        block_type: '',
        block_type_full: '',
        block: '',
        entrance: null,
        floor: null,
        flat_fias_id: '',
        flat_cadnum: null,
        flat_type: '',
        flat_type_full: '',
        flat: '',
        flat_area: '',
        square_meter_price: '',
        flat_price: null,
        postal_box: '',
        fias_id: '8b930aeb-e13a-49a6-bc74-7d1bd1a965ac',
        fias_code: '',
        fias_level: '7',
        fias_actuality_state: '0',
        kladr_id: '77000000000176200',
        geoname_id: '524901',
        capital_marker: '0',
        okato: '45280565000',
        oktmo: '45355000',
        tax_office: '7716',
        tax_office_legal: '7716',
        timezone: '',
        geo_lat: '55.878682',
        geo_lon: '37.685377',
        beltway_hit: null,
        beltway_distance: '',
        metro: null,
        divisions: '',
        qc_geo: '2',
        qc_complete: null,
        qc_house: null,
        history_values: null,
        unparsed_parts: null,
        source: '',
        qc: null,
      },
    },
  ],
};

export const BOUND_STREET_2: {
  suggestions: DaDataAddressSuggestion[] | DaDataAddressSuggestion;
} = {
  suggestions: {
    value: 'Машкинское шоссе',
    unrestricted_value: 'г Москва, Машкинское шоссе',
    data: {
      postal_code: '',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Центральный',
      region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      region_kladr_id: '7700000000000',
      region_iso_code: 'RU-MOW',
      region_with_type: 'г Москва',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Москва',
      area_fias_id: '',
      area_kladr_id: '',
      area_with_type: '',
      area_type: '',
      area_type_full: '',
      area: '',
      city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      city_kladr_id: '7700000000000',
      city_with_type: 'г Москва',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Москва',
      city_area: 'Северный',
      city_district_fias_id: '',
      city_district_kladr_id: '',
      city_district_with_type: '',
      city_district_type: '',
      city_district_type_full: '',
      city_district: '',
      settlement_fias_id: '',
      settlement_kladr_id: '',
      settlement_with_type: '',
      settlement_type: '',
      settlement_type_full: '',
      settlement: '',
      street_fias_id: 'dd3c2aeb-bc69-47ae-8295-f46e2da2bc60',
      street_kladr_id: '77000000000371600',
      street_with_type: 'Машкинское шоссе',
      street_type: 'ш',
      street_type_full: 'шоссе',
      street: 'Машкинское',
      stead_fias_id: '',
      stead_cadnum: null,
      stead_type: '',
      stead_type_full: '',
      stead: '',
      house_fias_id: '',
      house_kladr_id: '',
      house_cadnum: null,
      house_type: '',
      house_type_full: '',
      house: '',
      block_type: '',
      block_type_full: '',
      block: '',
      entrance: null,
      floor: null,
      flat_fias_id: '',
      flat_cadnum: null,
      flat_type: '',
      flat_type_full: '',
      flat: '',
      flat_area: '',
      square_meter_price: '',
      flat_price: null,
      postal_box: '',
      fias_id: 'dd3c2aeb-bc69-47ae-8295-f46e2da2bc60',
      fias_code: '',
      fias_level: '7',
      fias_actuality_state: '0',
      kladr_id: '77000000000371600',
      geoname_id: '524901',
      capital_marker: '0',
      okato: '45000000000',
      oktmo: '45000000',
      tax_office: '7733',
      tax_office_legal: '7733',
      timezone: '',
      geo_lat: '55.910685',
      geo_lon: '37.375881',
      beltway_hit: null,
      beltway_distance: '',
      metro: null,
      divisions: '',
      qc_geo: '2',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: '',
      qc: null,
    },
  },
};
