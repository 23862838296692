import { useTranslation } from 'react-i18next';

import { Datepicker } from '@pulse-web-ui/datepicker';
import { Input } from '@pulse-web-ui/input';
import { HelperText } from '@pulse-web-ui/helper-text';

import { Title } from '../title';

import {
  Container,
  InputContainer,
  InputRow,
  InputTraveler,
} from './traveler.styles';
import { i18nDefaultValues } from '../../i18n';

import type { FC } from 'react';
import type { Props } from './traveler.types';

export const Traveler: FC<Props> = ({
  serialNo,
  remove,
  value,
  onChange,
  onBlur,
  error,
  trigger,
  name: fieldName,
  fieldsLength,
  disabled,
  minBirthday,
  maxBirthday,
  onDataEntered,
}) => {
  const { t } = useTranslation();

  const handleName = (name: string) => {
    onChange({
      ...value,
      name,
    });

    trigger(`${fieldName}.name`);
  };

  const handleSurname = (surname: string) => {
    onChange({
      ...value,
      surname,
    });

    trigger(`${fieldName}.surname`);
  };

  const handleBirthDay = (date: Date | null) => {
    if (date) {
      onChange({
        ...value,
        birthday: date,
      });

      const { name, surname } = value;

      if (name && surname && date) {
        onDataEntered();
        onBlur();
      }
    }

    trigger(`${fieldName}.birthday`);
  };

  const hanldeBlur = () => {
    const { name, surname, birthday } = value;
    if (name && surname && birthday) {
      onBlur();
      onDataEntered();
    }
  };

  const birthday = value?.birthday ? new Date(value.birthday) : null;
  const disabledBirthday = disabled;

  return (
    <Container>
      <Title serialNo={serialNo} remove={remove} fieldsLength={fieldsLength} />
      <InputContainer>
        <InputRow>
          <InputTraveler>
            <Input
              noMargin
              label={t('SMART:WhoAndHowPaperwork.labels.lastNameLatin', {
                defaultValue:
                  i18nDefaultValues.WhoAndHowPaperwork.labels.lastNameLatin,
              })}
              value={value.surname}
              onChange={handleSurname}
              onBlur={hanldeBlur}
              error={!!error?.surname}
              disabled={disabled}
              hintObject={{
                message: error?.surname?.message,
              }}
              data-test={`Last-name-${serialNo}`}
            />
          </InputTraveler>
          <InputTraveler>
            <Input
              noMargin
              label={t('SMART:WhoAndHowPaperwork.labels.firstNameLatin', {
                defaultValue:
                  i18nDefaultValues.WhoAndHowPaperwork.labels.firstNameLatin,
              })}
              value={value.name}
              disabled={disabled}
              onChange={handleName}
              onBlur={hanldeBlur}
              error={!!error?.name}
              hintObject={{
                message: error?.name?.message,
              }}
              data-test={`Name-${serialNo}`}
            />
          </InputTraveler>
        </InputRow>
        <InputRow>
          <InputTraveler half>
            <HelperText
              noMargin
              status={error?.birthday ? 'error' : 'default'}
              message={error?.birthday?.message}
            >
              <Datepicker
                label={t('SMART:WhoAndHowPaperwork.labels.birthday', {
                  defaultValue:
                    i18nDefaultValues.WhoAndHowPaperwork.labels.birthday,
                })}
                minDate={minBirthday}
                maxDate={maxBirthday}
                onChange={handleBirthDay}
                disabled={disabledBirthday}
                selected={birthday}
                error={!!error?.birthday}
                showYearDropdown
                showMonthDropdown
                showInput
                disabledKeyboardNavigation
                data-test={`Birthday-${serialNo}`}
              />
            </HelperText>
          </InputTraveler>
        </InputRow>
      </InputContainer>
    </Container>
  );
};
