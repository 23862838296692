import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getDiscount } from '@shared/utils';
import { Price } from '@shared/queries/application/get-price/types';

interface Props {
  prices: Price[];
}

export const PromoCodeDiscount = ({ prices }: Props) => {
  const { t } = useTranslation();

  const discountPrice = useMemo(() => {
    return prices.find((price) => price?.premiumAndDeltaPromo);
  }, [prices]);

  const promoDiscount = useMemo(
    () =>
      discountPrice &&
      discountPrice.premiumAndDeltaPromo &&
      getDiscount(
        discountPrice.premiumAndDelta,
        discountPrice.premiumAndDeltaPromo
      ),
    [prices]
  );

  if (!discountPrice) return null;

  return (
    <Trans
      values={{
        discount: promoDiscount,
      }}
    >
      {t('COMMON:hints.discountForFirstPeriod')}
    </Trans>
  );
};
